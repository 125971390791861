const openDataProtectionDialog = () => {
    document.getElementById("dialog-title").innerText = 'Data Protection';
    loadFileAndDisplayInDialog('static/txt/dsvgo-en.txt');
}

const openDatenschutzDialog = () => {
    document.getElementById("dialog-title").innerText = 'Datenschutzerklärung';
    loadFileAndDisplayInDialog('static/txt/dsvgo-de.txt');
}

const openLegalNoticeDialog = () => {
    document.getElementById("dialog-title").innerText = 'Legal Notice';
    loadFileAndDisplayInDialog('static/txt/impressum-en.txt');
}

const openImpressumDialog = () => {
    document.getElementById("dialog-title").innerText = 'Impressum';
    loadFileAndDisplayInDialog('static/txt/impressum-de.txt');
}

function loadFileAndDisplayInDialog(file) {
    jQuery.get(file, function(data) {
        document.getElementById('dialog-content').innerHTML = data;
        document.getElementById('dialog').style = "display: block;"
        jQuery('#dialog-content').scrollTop(0);
    });
}

function closeDialog() {
    document.getElementById('dialog').style = "display: none;"
}

export function exposeDialogFunctions() {
    window.openDataProtectionDialog = openDataProtectionDialog;
    window.openDatenschutzDialog = openDatenschutzDialog;
    window.openLegalNoticeDialog = openLegalNoticeDialog;
    window.openImpressumDialog = openImpressumDialog;
    window.closeDialog = closeDialog;
}