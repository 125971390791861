import 'breakpoints-js';
import 'jquery.scrollex';
import '../lib/jquery.scrolly.min';
import './template-util';
import './template-main';

import 'academicons';
import '@fortawesome/fontawesome-free/css/all'
import '@fortawesome/fontawesome-free/js/all'
import '../static/css/additional';

import { fillPublicationsList } from "./publications";
import { exposeDialogFunctions } from "./dialog";

window.onload = () => {
    fillPublicationsList();
    exposeDialogFunctions();
}