import '../static/css/publications';

const publications = [
    {
        type: 'journal',
        authors: 'Frederik L. Dennig, Matthias Miller, Daniel A. Keim, and Mennatallah El-Assady',
        title: 'FS/DS: A Theoretical Framework for the Dual Analysis of Feature Space and Data Space',
        published: 'IEEE Transactions on Visualization and Computer Graphics',
        year: 2023,
        note: '(to appear)',
        image: '/static/images/pub/fsds.png',
        pdf: '/static/pdf/Dennig2023.pdf',
        video: undefined,
        demo: undefined,
        code: undefined,
        doi: 'http://doi.org/10.1109/TVCG.2023.3288356',
        arxiv: undefined,
        dblp: undefined,
    },
    {
        type: 'dataset',
        authors: 'Frederik L. Dennig',
        title: 'Replication Data for: "Slope-Dependent Rendering of Parallel Coordinates to Reduce Density Distortion and Ghost Clusters"',
        published: 'https://doi.org/10.18419/darus-3060, DaRUS, V1, UNF:6:UBKuKSiQ9Yl4rH7r00rY3g== [fileUNF]',
        year: 2022,
        note: undefined,
        image: '/static/images/pub/pcp-rendering-data.png',
        pdf: undefined,
        video: undefined,
        demo: undefined,
        code: undefined,
        doi: 'https://doi.org/10.18419/darus-3060',
        arxiv: undefined,
        dblp: undefined,
    },
    {
        type: 'journal',
        authors: 'Quynh Quang Ngo, Frederik L. Dennig, Daniel A. Keim, and Michael Sedlmair',
        title: 'Machine learning meets visualization – Experiences and lessons learned',
        published: 'it - Information Technology; 65(4-5):169-180',
        year: 2022,
        note: undefined,
        image: '/static/images/pub/it-paper.png',
        pdf: '/static/pdf/Ngo2022.pdf',
        video: undefined,
        demo: undefined,
        code: undefined,
        doi: 'https://doi.org/10.1515/itit-2022-0034',
        arxiv: undefined,
        dblp: 'https://dblp.org/rec/journals/it/NgoDKS22.html?view=bibtex',
    },
    {
        type: 'journal',
        authors: 'Maximilian T. Fischer, Frederik L. Dennig, Daniel Seebacher, Daniel A. Keim, Mennatallah El-Assady',
        title: 'Communication Analysis through Visual Analytics: Current Practices, Challenges, and New Frontiers',
        published: 'Visualization in Data Science (VDS)',
        year: 2022,
        note: undefined,
        image: '/static/images/pub/comm-paper.png',
        pdf: '/static/pdf/Fischer2022.pdf',
        video: undefined,
        demo: undefined,
        code: undefined,
        doi: 'https://doi.org/10.1109/VDS57266.2022.00006',
        arxiv: 'https://arxiv.org/abs/2106.14802',
        dblp: undefined,
    },
    {
        type: 'dataset',
        authors: 'Frederik L. Dennig',
        title: 'Replication Data for: "ParSetgnostics: Quality Metrics for Parallel Sets"',
        published: 'https://doi.org/10.18419/darus-2869, DaRUS, V1, UNF:6:mmqXqGYXSM0L6g/xQCjGUg== [fileUNF]',
        year: 2022,
        note: undefined,
        image: '/static/images/pub/parsetgnostics-data.png',
        pdf: undefined,
        video: undefined,
        demo: undefined,
        code: undefined,
        doi: 'https://doi.org/10.18419/darus-2869',
        arxiv: undefined,
        dblp: undefined,
    },
    {
        type: 'inproceedings',
        authors: 'Frederik L. Dennig, Eren Cakmak, Henrik Plate, and Daniel A. Keim',
        title: 'VulnEx: Exploring Open-Source Software Vulnerabilities in Large Development Organizations to Understand Risk Exposure',
        published: 'Proceedings of the 18th IEEE Symposium on Visualization for Cyber Security (VizSec)',
        year: 2021,
        note: undefined,
        image: '/static/images/pub/vulnex.png',
        pdf: '/static/pdf/Dennig2021Vulnex.pdf',
        video: undefined,
        demo: 'https://dennig.dbvis.de/vulnex',
        code: 'https://github.com/dbvis-ukon/vulnex',
        doi: 'https://doi.org/10.1109/VizSec53666.2021.00014',
        arxiv: 'https://arxiv.org/abs/2108.06259',
        dblp: 'https://dblp.org/rec/conf/vizsec/DennigCPK21.html?view=bibtex',
    },
    {
        type: 'journal',
        authors: 'Frederik L. Dennig, Maximilian T. Fischer, Michael Blumenschein, Johannes Fuchs, Daniel A. Keim, and Evanthia Dimara',
        title: 'ParSetgnostics: Quality Metrics for Parallel Sets',
        published: 'Computer Graphics Forum; 40(3):375-386',
        year: 2021,
        note: undefined,
        image: '/static/images/pub/parsetgnostics.png',
        pdf: '/static/pdf/Dennig2021.pdf',
        video: undefined,
        demo: 'https://dennig.dbvis.de/parsetgnostics/',
        code: 'https://osf.io/rwhf5/',
        doi: 'https://doi.org/10.1111/cgf.14314',
        arxiv: undefined,
        dblp: 'https://dblp.org/rec/journals/cgf/DennigFBFKD21.html?view=bibtex',
    },
    {
        type: 'inproceedings',
        authors: 'David Pomerenke, Frederik L. Dennig, Daniel A. Keim, and Michael Blumenschein',
        title: 'Slope-Dependent Rendering of Parallel Coordinates to Reduce Density Distortion and Ghost Clusters',
        published: 'Proceedings of the IEEE Visualization Conference (VIS)',
        year: 2019,
        note: undefined,
        image: '/static/images/pub/pcp-rendering.png',
        pdf: '/static/pdf/Pomerenke2019.pdf',
        video: 'https://vimeo.com/373792354',
        demo: 'http://subspace.dbvis.de/pcp-adjustment',
        code: 'https://github.com/davidpomerenke/slope',
        doi: 'https://doi.org/10.1109/VISUAL.2019.8933706',
        arxiv: 'https://arxiv.org/abs/1907.12489',
        dblp: 'https://dblp.uni-trier.de/rec/bibtex/conf/visualization/PomerenkeDK0B19',
    },
    {
        type: 'inproceedings',
        authors: 'Frederik L. Dennig, Tom Polk, Zudi Lin, Tobias Schreck, Hanspeter Pfister, and Michael Behrisch',
        title: 'FDive: Learning Relevance Models using Pattern-based Similarity Measures',
        published: 'Proceedings of IEEE Conference on Visual Analytics Science and Technology (VAST)',
        year: 2019,
        note: undefined,
        image: '/static/images/pub/fdive.png',
        pdf: '/static/pdf/Dennig2019.pdf',
        video: 'https://vimeo.com/371537420',
        demo: undefined,
        code: undefined,
        doi: 'https://doi.org/10.1109/VAST47406.2019.8986940',
        arxiv: 'https://arxiv.org/abs/1907.12489',
        dblp: 'https://dblp.uni-trier.de/rec/bibtex/conf/ieeevast/DennigPLSP019',
    },
    {
        type: 'inproceedings',
        authors: 'Christin Schätzle, Frederik L. Dennig, Michael Blumenschein, Daniel A. Keim, and Miriam Butt',
        title: 'Visualizing Linguistic Change as Dimension Interactions',
        published: 'Proceedings of the 1st International Workshop on Computational Approaches to Historical Language Change',
        year: 2019,
        note: undefined,
        image: '/static/images/pub/histobankvis-2.png',
        pdf: '/static/pdf/Schaetzle2019.pdf',
        video: undefined,
        demo: 'https://dennig.dbvis.de/histobankvis',
        code: undefined,
        doi: undefined,
        arxiv: undefined,
        dblp: undefined,
    },
    {
        type: 'inproceedings',
        authors: 'Chrisitin Schätzle, Michael Hund, Frederik L. Dennig, Miriam Butt, and Daniel A. Keim',
        title: 'HistoBankVis: Detecting Language Change via Data Visualization',
        published: 'Proceedings of the NoDaLiDa 2017 Workshop on Processing Historical Language',
        year: 2017,
        note: undefined,
        image: '/static/images/pub/histobankvis.png',
        pdf: "/static/pdf/Schaetzle2017.pdf",
        video: undefined,
        demo: 'https://dennig.dbvis.de/histobankvis',
        code: undefined,
        doi: undefined,
        arxiv: undefined,
        dblp: 'https://dblp.org/rec/bibtex/conf/histlang/SchatzleHDBK17',
    }
];

const pubLinkClasses = [
    {
        type: 'pdf',
        classes: 'fas fa-file-pdf pub-link',
        title: 'PDF'
    },
    {
        type: 'video',
        classes: 'fas fa-video pub-link',
        title: 'Video'
    },
    {
        type: 'demo',
        classes: 'fas fa-play-circle pub-link',
        title: 'Live prototype'
    },
    {
        type: 'code',
        classes: 'fas fa-code pub-link',
        title: 'Source code'
    },
    {
        type: 'doi',
        classes: 'ai ai-doi ai-lg pub-link',
        title: 'DOI'
    },
    {
        type: 'arxiv',
        classes: 'ai ai-arxiv ai-lg pub-link',
        title: 'arXiv publication'
    },
    {
        type: 'dblp',
        classes: 'ai ai-dblp ai-lg pub-link',
        title: 'dblp bibliography'
    }
];

export function fillPublicationsList() {
    const pl = document.getElementById('publications-list');
    for (const p of publications) {
        const row = document.createElement('div');
        row.className = "row";
        row.style.marginTop = 0;
        const imageCol = document.createElement('div');
        imageCol.className = "col-2 col-12-narrower";
        const ie = document.createElement('img');
        ie.className = 'pub-image';
        ie.src = p.image;
        imageCol.appendChild(ie);
        const textCol = document.createElement('div');
        textCol.className = "col-10 col-12-narrower";
        const te = document.createElement('div');
        te.className = 'pub-entry'
        const ta = document.createElement('div');
        ta.className = 'pub-authors';
        ta.textContent = p.authors;
        const tt = document.createElement('div');
        tt.className = 'pub-title';
        tt.textContent = p.title;
        const tp = document.createElement('div');
        tp.className = 'pub-published';
        tp.textContent = p.published + ', ' + p.year + '.';
        te.appendChild(ta);
        te.appendChild(tt);
        te.appendChild(tp);
        for (const l of pubLinkClasses) {
            if (p[l.type]) {
                const a = document.createElement('a');
                const i = document.createElement('i');
                i.className = l.classes;
                a.appendChild(i);
                a.href = p[l.type];
                a.title = l.title;
                te.appendChild(a);
            }
        }
        textCol.appendChild(te);
        row.appendChild(imageCol);
        row.appendChild(textCol);
        pl.appendChild(row);
    }
}